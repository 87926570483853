import VueCompositionApi, { reactive } from '@vue/composition-api';
import I18n from 'i18n-js';
import Vue from 'vue';
import { loadLocales } from './load-locales';
import { generateTranslateDom } from '@/exportables/utils/translation-keymap-generator.js';

Vue.use(VueCompositionApi);

const defaultLocale = 'ko';
const { documentElement } = document;
const getLang = () => documentElement.getAttribute('lang') === 'en' ? 'en' : defaultLocale;

const lang = getLang();

const originalI18nT = I18n.t;
const parentDomforCache: HTMLElement | null = null;

Object.assign(I18n, {
  defaultLocale,
  fallbacks: true,
  locale: lang,
  translations: loadLocales(),
  t: function (scope: string, options?: Record<string, string>) {
    const translatedText = originalI18nT(scope, options);
    generateTranslateDom(parentDomforCache, scope, translatedText);
    return translatedText;
  },
  tbd: function (scope: string, site?: string, options?: I18n.InterpolateOptions) {
    const translatedText = originalI18nT(`${site}.${scope}`, { ...options, defaults: [{ scope }] });
    generateTranslateDom(parentDomforCache, `${site}.${scope}`, translatedText);
    return translatedText;
  },
  zendeskLocale: lang === 'ko' ? 'ko' : 'en-us',
});

const mutationObserver = new MutationObserver((mutations) => {
  for (const { target, type } of mutations) {
    if (target === documentElement && type === 'attributes') {
      const lang = getLang();
      I18n.locale !== lang && (I18n.locale = lang);
    }
  }
});
mutationObserver.observe(
  documentElement,
  { attributes: true },
);

export const useI18n = () => reactive(I18n);
