import { TRANSLATION_ID } from '@/exportables/constants/translation_key_map.constant.js';

export const createDomElement = (type, tags) => {
  const element = document.createElement(type);
  for (const [key, value] of Object.entries(tags)) {
    element[key] = value;
  }
  return element;
};

export const isTranslationKeyExists = (domEl, key) => domEl.innerText.indexOf(`\n${key}\n`) >= 0;

export const generateTranslationRow = (key, value, koValue) => {
  const rowEl = createDomElement('div', {
    className: 'row',
  });

  const keyEl = createDomElement('div', {
    className: 'key',
  });

  const keyValueEl = createDomElement('div', {
    className: 'key-value',
    innerText: key,
    id: key,
  });

  const koValueEl = createDomElement('div', {
    className: 'ko-value',
    innerText: koValue ? koValue : '',
  });

  keyEl.appendChild(keyValueEl);
  keyEl.appendChild(koValueEl);

  const valueEl = createDomElement('div', {
    className: 'value',
  });

  const inputEl = createDomElement('input', {
    type: 'text',
    className: 'value-input',
    placeholder: value,
    value,
    name: key,
  });

  const buttonEl = createDomElement('button', {
    innerText: 'Apply',
    className: 'value-btn btn',
    disabled: true,
  });

  valueEl.appendChild(inputEl);
  valueEl.appendChild(buttonEl);

  rowEl.appendChild(keyEl);
  rowEl.appendChild(valueEl);

  return rowEl;
};

export const copyToClipboard = (id) => {
  const r = document.createRange();
  const target = document.getElementById(id);
  if (target) {
    r.selectNode(target);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  }
};

export const generateTranslateDom = (parentDomforCache, scope, translatedText) => {
  const targetDom = parentDomforCache || document.getElementById(TRANSLATION_ID);
  if (targetDom) {
    if (!isTranslationKeyExists(targetDom, scope)) {
      const koValue = scope.split('.').reduce((o, key) => o[key] ? o[key] : false, I18n.translations.ko);
      if (!koValue) return;
      const row = generateTranslationRow(scope, translatedText, koValue);
      targetDom.appendChild(row);
    }
  }
};
